.image-container {
    margin: 5vw;
    width: 90vw;
    text-align: center;
  }
  
  .image-container img {
    width: 100%;
    max-width: 100%;
    height: auto;
    max-height: 90vh;
    object-fit: contain;
  }
  
  @media (min-width: 600px) {
    .image-container {
      width: auto;
    }
  
    .image-container img {
      width: auto;
      height: 90vh; /* 90% of viewport height on desktop */
    }
  }
  